.itemDisplay {
    width: 33px;
    height: 33px;
}

.currencyDisplay {
    width: 36px;
    height: 36px;
}

.imageContainer {
    position: relative;
    text-align: center;
    display: inline-block;
    color: black;
    text-shadow: 2px 0 #fff, -2px 0 #fff, 0 2px #fff, 0 -2px #fff, 1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -1px 1px #fff;
}

.imageCentered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.imageBottom {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -100%);
}

.numberDisplay {
    font-size: x-small;
}

.changedDisplayNegative {
    color: #9C1A1C;
}

.changedDisplayPositive {
    color: #3A7734;
}

.spanNewLine {
    display: block;
}

