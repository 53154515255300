/* taken from https://www.codevertiser.com/reactjs-responsive-navbar/ */

.container {
    max-width: 1100px;
    margin: 0 auto;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.navbar {
    height: 60px;
    background-color: #fef7e5;
    position: relative;
}

.nav-elements {
}

.nav-elements ul {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
}

.nav-elements ul li:not(:last-child) {
    margin-right: 60px;
}

.nav-elements ul a {
    font-size: 16px;
    font-weight: 400;
    color: #2f234f;
    text-decoration: none;
}

.nav-elements ul a.active {
    color: #574c4c;
    font-weight: 500;
    position: relative;
}

.nav-elements ul a.active::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #574c4c;
}